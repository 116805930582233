import { createSlice } from "@reduxjs/toolkit";

export const reserveSlice = createSlice({
    name: 'reserve',
    initialState: {
        iDate: '',
        oDate: '',
        selRoomArr: [],
        cost: '',
        gst: '',
        total: '',
        discount: '',
        discountType: '',
        disAmt: '',
        sourceId: '',
        sourceTypeId: '',
        customerId: '',
        paymentTypeId: '',
        paymentTypeName: '',
        paymentModeId: '',
        specialNote: '',
        booker: ''
    },
    reducers: {
        setIDate: (state, action) => { state.iDate = action.payload },
        setODate: (state, action) => { state.oDate = action.payload },
        setSelRoomArr: (state, action) => { state.selRoomArr = action.payload },
        setPrice: (state, action) => {
            state.cost = action.payload.netTotal
            state.gst = action.payload.gTax
            state.total = action.payload.gTotal
            state.discount = action.payload.discount
            state.discountType = action.payload.discountType
            state.disAmt = action.payload.disAmt
        },
        setSrcId: (state, action) => { state.sourceId = action.payload },
        setSrcTypeId: (state, action) => { state.sourceTypeId = action.payload },
        setGuestId: (state, action) => { state.customerId = action.payload },
        setPytTypeId: (state, action) => { state.paymentTypeId = action.payload },
        setPaymentTypeName: (state, action) => { state.paymentTypeName = action.payload },
        setPytModeId: (state, action) => { state.paymentModeId = action.payload },
        setSpecialNote: (state, action) => { state.specialNote = action.payload },
        setBookedBy: (state, action) => { state.booker = action.payload },
        disposeNewStore: (state, action) => {
            if (action.payload) {
                state.iDate = ''
                state.oDate = ''
                state.selRoomArr = []
                state.cost = ''
                state.gst = ''
                state.total = ''
                state.discount = ''
                state.discountType = ''
                state.disAmt = ''
                state.sourceId = ''
                state.sourceTypeId = ''
                state.customerId = ''
                state.paymentTypeId = ''
                state.paymentTypeName = ''
                state.paymentModeId = ''
                state.specialNote = ''
                state.booker = ''
            }
        }
    }
})

export const {
    setIDate,
    setODate,
    setSelRoomArr,
    setPrice,
    setSrcId,
    setSrcTypeId,
    setGuestId,
    setPytTypeId,
    setPaymentTypeName,
    setPytModeId,
    setSpecialNote,
    setBookedBy,
    disposeNewStore
} = reserveSlice.actions

export default reserveSlice.reducer