import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../API/axios';
import { arrayToObject } from '../common/commonMethods';

export const storeInvoiceDetails = createAsyncThunk(
    "invoiceDetails",
    async (obj) => {
        try {
            const { bookingID, LoginID, Token } = obj
            const res = await axios.get(`/booking/BookingInvoice`, {
                params: {
                    LoginID,
                    Token,
                    BookingId: bookingID
                }
            })
            if (res?.data.length > 0) {
                return res.data
            }
        } catch (err) {
            console.log('voucherSliceErr', err)
        }
    }
)

export const storeBookingDetails = createAsyncThunk(
    "bookingDetails",
    async (obj) => {
        try {
            const { BookingId, LoginID, Token } = obj;
            const params = "BookingId=" + BookingId +
                "&LoginId=" + LoginID +
                "&Token=" + Token;
            const result = await axios.get("/booking/BookingVoucher?" + params);
            // console.log("result", result)
            const data = arrayToObject(result.data.slice(0, 6));
            data.roomData = result.data[4]
            return data;
        }
        catch (error) {
            console.log(error)
        }

    }
)
export const LaundryInvoiceDetails = createAsyncThunk(
    "laundryInvoice",
    async (obj) => {
        try {

            const result = await axios.post("/laundry/transaction", obj);
            console.log("laundryres: ", result)
            const data = arrayToObject(result.data.slice(0, 2));
            data.laundryItemsData = result.data[2]
            return data;
        }
        catch (error) {
            console.log(error)
        }

    }
)
export const voucherSlice = createSlice({
    name: "bookingData",
    initialState: {
        bookingId: null,
        invoiceDetails: [],
        bookingDetails: null,
        laundryDetails: null,
        invoiceID: '',
        posInvoiceID: ''
    },
    reducers: {
        setBookingId: (state, action) => { state.bookingId = action.payload },
        setInvoiceID: (state, action) => { state.invoiceID = action.payload },
        setPosInvoiceID: (state, action) => { state.posInvoiceID = action.payload }
    },
    extraReducers: (builder) => {
        builder.addCase(storeInvoiceDetails.fulfilled, (state, action) => {
            return { ...state, invoiceDetails: action.payload }
        }),
            builder.addCase(storeBookingDetails.fulfilled, (state, action) => {
                return { ...state, bookingDetails: action.payload }
            }),
            builder.addCase(LaundryInvoiceDetails.fulfilled, (state, action) => {
                return { ...state, laundryDetails: action.payload }
            })
    }
})

export const { setBookingId, setInvoiceID, setPosInvoiceID } = voucherSlice.actions

export default voucherSlice.reducer

